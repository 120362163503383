import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Rankings from "./pages/Rankings";
import Players from "./pages/Players";
import Events from "./pages/Events";
import PremierLeague from "./pages/PremierLeague";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from './pages/404NotFound';
import ContactForm from "./pages/Contact";
import AboutADO from "./pages/About";
import Tournaments from "./pages/Tournaments";
import RecipeReviewCard from "./pages/CardPractise";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="rankings" element={<Rankings />} />
          <Route path="players" element={<Players />} />
          <Route path="events" element={<Events />} />
          <Route path="premierleague" element={<PremierLeague />} />
          <Route path="tournaments" element={<Tournaments />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="contactus" element={<ContactForm />} />
          <Route path="aboutus" element={<AboutADO />} />
          <Route path="*" element={<NotFound />} />
          <Route path="cardpractise" element={<RecipeReviewCard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);