import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import './style/Footer.css';
// Footer Component
function Footer() {
  return (
    <footer>
      <Grid item xs={12} style={{ padding: '0.25vh', backgroundColor: '#0582CA' }}></Grid>
      <Grid container style={{ flex: 1, maxWidth: '1000px', margin: 'auto', boxSizing: 'border-box', padding: '1vh' }}>

        <Grid item xs={6} style={{ borderRight: '#0582CA 1px solid', padding: '1vh' }}>
          <Typography variant="footer" style={{ textAlign: 'center', fontWeight: 'bold' }}>General</Typography>
          <Typography variant="footer" className="social-media" style={{ textAlign: 'center' }}>
            <Link to="/aboutus">About the ADO</Link>
          </Typography>
          <Typography variant="footer" className="social-media" style={{ textAlign: 'center' }}>
            <Link to="/contactus">Contact Us</Link>
          </Typography>
          <Typography variant="footer" className="social-media" style={{ textAlign: 'center' }}>
            <Link to="/privacypolicy">Privacy Policy</Link>
          </Typography>
        </Grid>

        <Grid item xs={6} style={{ padding: '1vh' }}>
          <Typography variant="footer" style={{ textAlign: 'center', fontWeight: 'bold' }}>Socials</Typography>
          <Typography variant="footer" className="social-media" style={{ textAlign: 'center' }}>
            <a href="https://www.youtube.com/@AmateurDartsOrganisation"><YouTubeIcon style={{ marginRight: 4 }} />YouTube</a>
          </Typography>
          <Typography variant="footer" className="social-media" style={{ textAlign: 'center' }}>
            <a href="https://www.facebook.com/@AmateurDartsOrganisation"><FacebookIcon style={{ marginRight: 4 }} />Facebook</a>
          </Typography>
          <Typography variant="footer" className="social-media" style={{ textAlign: 'center' }}>
            <a href="https://twitter.com/AmateurDartsOrg"><TwitterIcon style={{ marginRight: 4 }} />Twitter</a>
          </Typography>
          <Typography variant="footer" className="social-media" style={{ textAlign: 'center' }}>
            <a href="https://www.instagram.com/amateurdartsorg/"><InstagramIcon style={{ marginRight: 4 }} />Instagram</a>
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ padding: '1vh' }}>
          <Typography variant="footer" style={{ textAlign: 'right' }}>&copy; 2022 -  {new Date().getFullYear()} Amateur Darts Organisation</Typography>
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;